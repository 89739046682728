{
  "v": "4.8.0",
  "meta": {
    "g": "LottieFiles",
    "a": "",
    "k": "",
    "d": "",
    "tc": ""
  },
  "fr": 60,
  "ip": 0,
  "op": 360,
  "w": 530,
  "h": 530,
  "nm": "CM Animation",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Center",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  0.771
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  -0.866
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  -1.314
                ]
              },
              "t": 254,
              "s": [
                365
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  -2.426
                ]
              },
              "t": 294,
              "s": [
                355
              ]
            },
            {
              "t": 320,
              "s": [
                360
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            265,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  37.5
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  0.779,
                  0.779,
                  31.428
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 24,
              "s": [
                150,
                150,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  -0.541,
                  -0.541,
                  -6.762
                ]
              },
              "t": 42,
              "s": [
                95,
                95,
                100
              ]
            },
            {
              "t": 46,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      60.751,
                      0
                    ],
                    [
                      0,
                      -60.751
                    ],
                    [
                      -60.751,
                      0
                    ],
                    [
                      0,
                      60.751
                    ]
                  ],
                  "o": [
                    [
                      -60.751,
                      0
                    ],
                    [
                      0,
                      60.751
                    ],
                    [
                      60.751,
                      0
                    ],
                    [
                      0,
                      -60.751
                    ]
                  ],
                  "v": [
                    [
                      0,
                      -110
                    ],
                    [
                      -110,
                      0
                    ],
                    [
                      0,
                      110
                    ],
                    [
                      110,
                      0
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.737254901961,
                  0.827450980392,
                  0.96862745098,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  1,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  75.92,
                  75.92
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Ellipse 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 360,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Petal 8",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -315,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            4,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 122,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 246,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 420,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 122,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 246,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 122,
      "op": 422,
      "st": 122,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Petal 7",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -270,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            4,
            6,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 110,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 234,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 408,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 110,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 234,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 110,
      "op": 410,
      "st": 110,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Petal 6",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -225,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            8,
            8,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 98,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 222,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 396,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 98,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 222,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 98,
      "op": 398,
      "st": 98,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "Petal 5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -180,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            8,
            2,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 84,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 208,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 382,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 84,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 208,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 84,
      "op": 384,
      "st": 84,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "Petal 4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -135,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            6,
            -2,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 68,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 192,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 366,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 68,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 192,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 68,
      "op": 378,
      "st": 68,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "Petal 3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -90,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            6,
            -2,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 52,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 176,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 350,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 52,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 176,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 52,
      "op": 378,
      "st": 52,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "Petal 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -45,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            2,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 32,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 156,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 330,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 32,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      18.5
                    ],
                    "ti": [
                      0,
                      -18.5
                    ]
                  },
                  {
                    "t": 156,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 32,
      "op": 378,
      "st": 32,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "Petal 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 18,
                    "s": [
                      116,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 32,
                    "s": [
                      116,
                      82.355
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833,
                        0.833
                      ],
                      "y": [
                        0.833,
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167,
                        0.167
                      ],
                      "y": [
                        0.167,
                        0.167
                      ]
                    },
                    "t": 142,
                    "s": [
                      116,
                      222
                    ]
                  },
                  {
                    "t": 316,
                    "s": [
                      116,
                      220
                    ]
                  }
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 18,
                    "s": [
                      0,
                      0
                    ],
                    "to": [
                      0,
                      7.324
                    ],
                    "ti": [
                      0,
                      -17.833
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 32,
                    "s": [
                      0,
                      41.178
                    ],
                    "to": [
                      0,
                      27.214
                    ],
                    "ti": [
                      0,
                      -11.176
                    ]
                  },
                  {
                    "t": 142,
                    "s": [
                      0,
                      111
                    ]
                  }
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        }
      ],
      "ip": 18,
      "op": 378,
      "st": 18,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "Petal BG Strokes",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 336,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            260.25,
            265,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 0,
                "k": [
                  116,
                  220
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 20,
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = $bm_div(content('Rectangle 1').content('Rectangle Path 1').size[0], 2);"
              },
              "nm": "Rectangle Path 1",
              "mn": "Rect",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 0,
                    "s": [
                      0.600000023842,
                      0.600000023842,
                      0.600000023842,
                      1
                    ]
                  },
                  {
                    "t": 336,
                    "s": [
                      0.149019613862,
                      0.321568638086,
                      0.600000023842,
                      1
                    ]
                  }
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 52,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431372549,
                  0.654901960784,
                  0.941176470588,
                  1
                ],
                "ix": 4,
                "x": "var $bm_rt;\n$bm_rt = content('Rectangle 1').content('Stroke 1').color;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  4.5,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  111
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6,
                "x": "var $bm_rt;\n$bm_rt = thisComp.layer('Center').transform.rotation;"
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "Group",
          "hd": false
        },
        {
          "ty": "rp",
          "c": {
            "a": 0,
            "k": 8,
            "ix": 1
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 2
          },
          "m": 1,
          "ix": 2,
          "tr": {
            "ty": "tr",
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                0.8,
                -1.5
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ],
              "ix": 3
            },
            "r": {
              "a": 0,
              "k": 44.5,
              "ix": 4
            },
            "so": {
              "a": 0,
              "k": 100,
              "ix": 5
            },
            "eo": {
              "a": 0,
              "k": 100,
              "ix": 6
            },
            "nm": "Transform"
          },
          "nm": "Repeater 1",
          "mn": "Repeater",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 378,
      "st": 8,
      "bm": 0
    }
  ],
  "markers": []
}
