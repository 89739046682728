.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #000 url('../../img/transparency.svg') repeat;
  background-size: 32px;
  cursor: pointer;
}
